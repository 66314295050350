<template>
  <v-layout>
    <SideBarLists
      :providerId="providerId"
      :productList="productList"
      v-if="isLargeScreen"
    />
    <ProductItems :providerId="providerId" :productListKey="productListKey" />
  </v-layout>
</template>

<script>
export default {
  name: "ProductListView",
  components: {
    SideBarLists: () =>
      import("@/modules/FrontProvider/components/productList/SideBarList.vue"),
    ProductItems: () =>
      import("@/modules/FrontProvider/components/productList/ProductItem.vue"),
  },
  data() {
    return {
      isLargeScreen: window.innerWidth > 768,
      productListKey: {},
      productList: [],
      providerId: this.$route.params.providerId,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.getProductApi();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isLargeScreen = window.innerWidth > 768;
    },
    productItemincategories(categories, productList) {
      if (productList) {
        productList.forEach((product) => {
          product.menus.forEach((menuId) => {
            let category = categories.find(
              (category) => category.id === menuId
            );
            if (category) {
              if (!category["productItem"]) {
                category["productItem"] = [];
              }
              category["productItem"].push(product);
            }
          });
        });
        this.productList = categories.filter(
          (item) => item.productItem != undefined
        );
        const productListKey = categories.reduce((acc, cur) => {
          acc[cur.id] = cur;
          return acc;
        }, {});
        this.productListKey = productListKey;
        console.log(productListKey);
      } else {
        console.error("productList or productList.menus is undefined");
      }
    },
    async getProductApi() {
      this.$store.dispatch("loading/active");
      try {
        const productList = await this.$api.collection.publicApi.getProductIndex(
          this.$route.params.providerId
        );
        const categories = await this.$api.collection.publicApi.getProductMenu(
          this.$route.params.providerId
        );
        // console.log(data);
        this.productItemincategories(categories.data, productList.data);
      } catch (err) {
        console.error(err);
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
  },
};
</script>

<style lang="sass" scoped>
$breakpoint-sm: 768px
main
  padding: 2em
  grid-gap: 1em
  display: grid
  grid-template-columns: 1fr 3fr
  @media (max-width: $breakpoint-sm)
    grid-template-columns: 1fr
</style>
